<!--
 * @Author: your name
 * @Date: 2021-02-19 11:21:51
 * @LastEditTime: 2021-04-07 16:34:45
 * @LastEditors: Please set LastEditors
 * @Description: 修改查看
 * @FilePath: \sd-vue-admin\src\views\project\finance\client\receipt\compontents\InfoReceipt.vue
-->
<template xmlns="http://www.w3.org/1999/html">
  <div>
    <el-dialog :modal="false" :visible.sync="receipt" :title="title">
      <div>
        <div
          v-show="showtit == 1 && form.bill_status == 3"
          style="position: absolute; top: 23px; left: 160px; color: #0aaafa"
        >
          作废人：{{ form.scrap_name }} 作废时间：{{ form.scrap_time }}
        </div>
        <el-row class="dingwei">
          <el-col :span="24">
            <el-form ref="form" :model="form" inline label-width="100px">
              <el-form-item label="客户名称">
                <el-input
                  v-model="form.cust_name"
                  disabled
                  placeholder="客户名称"
                  style="width: 220px"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系人">
                <el-input
                  v-model="form.boss"
                  disabled
                  placeholder="联系人"
                  style="width: 220px"
                ></el-input>
              </el-form-item>
              <el-form-item label="收款类型">
                <el-input
                  v-model="form.bill_type_text"
                  disabled
                  placeholder="收款类型"
                  style="width: 220px"
                ></el-input>
              </el-form-item>
              <el-form-item label="收款时间">
                <el-input
                  v-model="form.add_time"
                  disabled
                  placeholder="收款时间"
                  style="width: 220px"
                ></el-input>
              </el-form-item>
              <div>
                <el-form-item label="原始单据">
                  <!--                <el-input-->
                  <!--                  v-model="form.order_ori_code"-->
                  <!--                  disabled-->
                  <!--                  placeholder="原始单据"-->
                  <!--                  style="width: 220px"-->
                  <!--                ></el-input>-->
                  <div style="cursor: pointer" @click="handleCheckOrderori(form)">{{form.order_ori_code}}</div>
                </el-form-item>
              </div>

              <el-form-item label="抵欠款单据" v-show="form.bill_type==7">
                <span style="cursor: pointer" @click="handleCheckOrder(form)">{{form.order_return_code}}</span>
<!--                <el-input
                  @click="handleCheckOrder(form)"
                  v-model="form.order_return_code"
                  placeholder="抵欠款单据"
                  style="width: 220px"
                ></el-input>-->
              </el-form-item>
              <div></div>
              <el-form-item label="收款人" prop="user_id">
                <el-input
                  v-model="form.add_name"
                  disabled
                  placeholder="收款人"
                  style="width: 220px"
                ></el-input>
              </el-form-item>
              <el-form-item label="收款金额" prop="clear_type">
                <el-input
                  v-model="form.bill_amount"
                  disabled
                  placeholder="收款金额"
                  style="width: 220px"
                ></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="remark">
                <el-input
                  v-model="form.remark"
                  type="textarea"
                  :disabled="showtit == 1"
                  placeholder="备注"
                  style="width: 420px"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="24">
            <el-table stripe :data="tableData">
              <el-table-column
                v-for="(item, index) in celund"
                :key="index"
                :label="item.label"
                :align="item.align"
                :prop="item.prop"
              >
                <template v-if="item.label == '收款方式'" #default="{ row }">
                  <el-select
                    v-model="row.pay_type"
                    placeholder="请选择付款方式"
                    :disabled="showtit == 1"
                  >
                    <el-option
                      v-for="list in account"
                      :key="list.id"
                      :label="list.name"
                      :value="list.id"
                    ></el-option>
                  </el-select>
                </template>
                <template v-else-if="item.label == '金额'" #default="{ row }">
                  <el-input
                    v-model="row.amount"
                    :disabled="showtit == 1"
                    placeholder="金额"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column v-if="showtit != 1" label="操作" align="center">
                <template #default="{ $index, row }">
                  <el-button type="text" @click="handlerAdd($index, row)">
                    添加
                  </el-button>
                  <el-button type="text" @click="handlerDelete($index, row)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row class="ziding">
          {{ form.bill_status_text }}
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button v-show="showtit == 2" type="primary" @click="handlersave">
          保 存
        </el-button>
        <el-button @click="receipt = !receipt">关 闭</el-button>
        <sale-order ref="saleOrder"></sale-order>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/Employee'
  import saleOrder from "@/views/project/sale/bills/saleOrder/components/checkOrder";
  export default {
    name: 'ShoukuanOrder',
    components: {saleOrder},
    data() {
      return {
        receipt: false,
        title: '收款单',
        showtit: 1,
        url: {
          account: '/promoteAdmin/deposit-order/pay-type',
          save: '/cashAdmin/cash/cash-add',
          compile: '/cashAdmin/cash/cash-detail',
          updated: '/cashAdmin/cash/cash-update',
          redo: '/cashAdmin/cash/cash-return',
        },
        form: {},
        tableData: [],
        account: [],
        celund: [
          {
            label: '收款方式',
            align: 'center',
            prop: '',
          },
          {
            label: '金额',
            align: 'center',
            prop: 'amount',
          },
        ],
        total: 0,
      }
    },
    computed: {
      money() {
        var sum = 0
        this.summe.forEach((list) => {
          sum += Number(list.qian_money)
        })
        return sum
      },
    },
    watch: {
      receipt(val) {
        if (!val) {
          this.showtit = 1
          this.title = '收款单'
        }
      },
    },
    mounted() {
      this.handleraccount()
    },
    methods: {
      handleCheckOrder(row){
        this.$refs.saleOrder.id = row.order_return_id
        this.$refs.saleOrder.isshowDialog = true
      },
      handleCheckOrderori(row){
        this.$refs.saleOrder.id = row.order_ori_id
        this.$refs.saleOrder.isshowDialog = true
      },
      handlerDelete($index, row) {
        this.tableData.splice($index, 1)
        if (this.tableData.length <= 0) {
          this.tableData = [
            {
              amount: '',
              pay_id: '',
              pay_type: '',
            },
          ]
        }
      },
      handlerAdd($index, row) {
        this.tableData.splice($index, 0, JSON.parse(JSON.stringify(row)))
      },
      handlerData(row) {
        postAction(this.url.compile, { id: row.id })
          .then((res) => {
            console.log(res, '详情')
            this.form = Object.assign({}, res.data)
            if (res.data.money_list.length > 0) {
              this.tableData = res.data.money_list
              this.tableData.forEach((list) => {
                list.pay_type = String(list.pay_type)
              })
            } else {
              this.tableData = [{ amount: '', pay_type: '' }]
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlersave() {
        switch (this.showtit) {
          case 1:
            this.receipt = false
            break
          case 2:
            this.handlerupdated()
            break
          case 3:
            this.handlerredo()
            break
          default:
            break
        }
      },
      handlerredo() {
        const pay = JSON.parse(JSON.stringify(this.tableData))
        pay.forEach((list) => {
          list.money = list.amount
          delete list.amount
        })
        const data = {
          id: this.form.id,
          remark: this.form.remark,
          pay_data: JSON.stringify(pay),
        }
        postAction(this.url.redo, data)
          .then((res) => {
            console.log(res, '')
            this.$message({
              message: res.msg,
              type: 'success',
            })
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerupdated() {
        const pay = JSON.parse(JSON.stringify(this.tableData))
        pay.forEach((list) => {
          list.money = list.amount
          delete list.amount
        })
        // pay.forEach((item) => {
        //   item.pay_id = item.pay_type
        // })
        const data = {
          id: this.form.id,
          pay_data: JSON.stringify(pay),
          remark: this.form.remark,
        }
        console.log('dd', data)
        postAction(this.url.updated, data)
          .then((res) => {
            console.log(res, '')
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.receipt = false
            this.$emit('getlist')
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      // 请选择付款方式
      handleraccount() {
        postAction(this.url.account, { pageSize: -1 })
          .then((res) => {
            console.log(res, '请选择付款方式')
            this.account = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>

<style>
  .dingwei {
    position: relative;
  }
  .ziding {
    position: absolute;
    top: 80px;
    right: 100px;
    padding: 30px 15px 30px 15px;
    color: #f8b703;
    border: 1px solid #f8b703;
    border-radius: 50%;
  }
</style>
